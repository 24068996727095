<template>
  <v-div class="ma-2">
    <v-row>
      <v-col>
        <h1 class="display-1 font-weight-bold">
          Unauthorized
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <a href="/">ポータルトップ</a>
        <span>|</span>
        <a href="#" @click="$auth.logout()">ログアウト</a>
      </v-col>
    </v-row>
  </v-div>
</template>

<script>
export default {
  beforeRouteEnter(to, from, next) {
    document.title = 'Unauthorized';
    next();
  },
};
</script>